import React from 'react'
import DuckPage from '../../components/duckPage'
import { Button } from '@chakra-ui/react'
import { navigate } from 'gatsby'

const AccountCreatedPage = () => (
	<DuckPage
		theme="light"
		parent="login"
		title={
			<>
				Thanks for <br />
				signing up!
			</>
		}
		topText="Your account has been created and you’re all set!"
		subtitle="You’ll need to confirm your booking with our staff in order to connect it to your account. "
		primaryBtn={
			<Button
				mb="50px"
				w="100%"
				variant="primary"
				onClick={() => navigate('/groups')}
			>
				Continue
			</Button>
		}
	/>
)
export default AccountCreatedPage
