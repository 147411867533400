import * as React from 'react'
import Layout from '../../layout'
import AccountCreatedPage from '../../pagesComponents/login/account-created'

const AccountCreated = () => {
	return (
		<Layout theme="light" title="Account Created">
			<AccountCreatedPage />
		</Layout>
	)
}

export default AccountCreated
